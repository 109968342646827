@import "~styles/_animations";

.fadeInDown {
  animation-name: fadeInDown;
  animation-duration: 0.45s;
  animation-fill-mode: both;
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeInLeft {
  animation-name: fadeInLeft;
  animation-duration: 0.45s;
  animation-fill-mode: both;
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.animate {
  @include staggered-z-index;
}
