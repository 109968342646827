@import "~styles/_animations";
@import "~styles/_colors";

.radio {
  display: flex;
  margin-bottom: 1.5em;
  user-select: none;
  flex-direction: column;

  .radio__label {
    font-weight: normal;
    font-size: 0.85em;
    margin-bottom: 1em;
    &:before {
      content: "";
      visibility: hidden;
      opacity: 0;
      transition: 0.25s;
    }

    &.bold {
      font-weight: bold;
    }
  }

  .radio__choices {
    display: flex;
    flex-direction: row;

    &.stacked {
      flex-direction: column;
    }
  }

  .radio__field {
    padding: 0.5em;
    margin: 0em 0.5em 0em 0em;
    border-radius: 50%;
    border: 1px solid $border-light;
    appearance: none;
    cursor: pointer;
    width: 1.2em;
    height: 1.2em;
    background: white;
    transition: 0.25s;

    &:hover {
      border-color: $border-dark;
    }

    &:focus,
    &:active {
      outline: none;
      border-color: $brand-secondary200;
    }

    &:hover,
    &:focus,
    &:active {
      box-shadow: rgba(0, 0, 0, 0.21) 0px 4px 17px 0px;
    }

    &.error {
      border-color: $color-danger;
    }

    &:checked {
      border-color: $brand-secondary;
      background-color: $brand-secondary;

      + .radio__label {
        &:before {
          content: "✓";
          visibility: visible;
          opacity: 1;
          color: white;
          position: absolute;
          left: 0.6em;
          font-size: 0.8em;
          font-weight: bold;
          top: 0.1em;
        }
      }
    }
  }

  .radio__error {
    color: $color-danger;
    margin-top: 0.5em;
    margin-bottom: 0;
    margin-left: 0.15em;
  }

  .radio__hint {
    color: $text-light;
    margin-top: 0.5em;
    margin-bottom: 0;
    margin-left: 0.15em;
  }

  .radio__field-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 0.8em;
    margin-right: 1.2em;
  }
}
