@import "~styles/_colors";

.alert-bar {
  padding: 1em 0.5em;
  font-size: 0.8em;

  &.alert-bar-info {
    background: $color-info;
  }

  &.alert-bar-warn {
    background: $color-warning;
  }

  &.alert-bar-error {
    background: $color-danger;
  }

  &.alert-bar-success {
    background: $color-success;
  }
}
