@import "~styles/_typography";
@import "~styles/_colors";

.logo {
  font-family: $font-logo-bold;
  display: flex;
  align-items: center;
  flex-direction: column;

  .logo__text {
    font-size: 2em;
    margin: 0;
    color: $text-dark;
  }

  .logo__icon {
    width: 2.5em;
    display: flex;
    align-items: center;

    svg {
      width: 100%;
      path {
        fill: $brand-primary;
      }
    }
  }
}
