@import "~styles/_animations";
@import "~styles/_colors";

.range-field {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5em;
  position: relative;

  .range-field__label {
    margin-bottom: 0.5em;
  }

  .range-field__input-wrapper {
    position: relative;
    height: 40px;
    margin-top: 1.5em;
    width: 100%;
    .range-field__bubble {
      position: absolute;
      background: white;
      border: 1px solid $brand-secondary200;
      width: 30px;
      text-align: center;
      border-radius: 10px;
      font-size: 0.8em;
      padding: 0.25em 0em;
      margin-left: -12.5px;
      top: -20px;
    }
    .range-field__field {
      border-radius: 3px;
      border: 1px solid $border-light;
      width: 100%;

      /*Chrome*/
      @media screen and (-webkit-min-device-pixel-ratio: 0) {
        -webkit-appearance: none;
        background-color: $border-light;

        &::-webkit-slider-runnable-track {
          height: 3px;
          -webkit-appearance: none;
          color: #13bba4;
        }

        &::-webkit-slider-thumb {
          width: 15px;
          -webkit-appearance: none;
          height: 15px;
          cursor: ew-resize;
          background: $brand-secondary200;
          box-shadow: $boxshadow-light;
          margin-top: -5px;
          border-radius: 100%;
        }
      }

      @supports (-moz-appearance: none) {
        background: white;
        border: none;
      }

      /** FF*/
      &::-moz-range-progress {
        background-color: $border-light;
      }
      &::-moz-range-track {
        background-color: $border-light;
      }
      &::-moz-range-thumb {
        background-color: $brand-secondary200;
        border-radius: 50%;
        border-color: $brand-secondary200;
      }
      /* IE*/
      &::-ms-fill-lower {
        background-color: $brand-secondary200;
      }
      &::-ms-fill-upper {
        background-color: $brand-secondary200;
      }

      &:focus,
      &:active {
        outline: none;
      }

      &:hover,
      &:focus,
      &:active {
        box-shadow: $boxshadow-light;
      }

      &.error {
        border-color: $color-danger;
      }
    }
  }

  .range-field__error {
    color: $color-danger;
    margin-top: 0.5em;
    margin-bottom: 0;
    margin-left: 0.15em;
  }

  .range-field__hint {
    color: $text-light;
    margin-top: 0.5em;
    margin-bottom: 0;
    margin-left: 0.15em;
  }
}
