@import "~styles/_animations";
@import "~styles/_colors";

.input {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5em;
}

.input__label {
  font-weight: bold;
  margin-bottom: 0.5em;
}

.input__field {
  padding: 1em;
  border-radius: 3px;
  border: 1px solid $border-light;

  &::placeholder {
    color: $border-light;
    transition: $baseTransition;
  }

  &:hover {
    border-color: $border-dark;

    &::placeholder {
      color: $border-dark;
    }
  }

  &:focus,
  &:active {
    outline: none;
    border-color: $brand-secondary200;

    &::placeholder {
      opacity: 0;
    }
  }

  &:hover,
  &:focus,
  &:active {
    box-shadow: $boxshadow-light;
  }

  &.error {
    border-color: $color-danger;
  }
}

.input__error {
  color: $color-danger;
  margin-top: 0.5em;
  margin-bottom: 0;
  margin-left: 0.15em;
}

.input__hint {
  color: $text-light;
  margin-top: 0.5em;
  margin-bottom: 0;
  margin-left: 0.15em;
}

.input__optional-label {
  font-weight: 400;
}
