// Reflective Brand Colors
$indigo-dye: #083d77;
$davys-grey: #4b4f58;

// LEGACY COLORS
$dark-cornflower-blue: #373e72;
$celadon-blue: #3978a1;
$fiery-rose: #f75970;
$powder-blue: #c8f0f8;
$cowork-blue: #6985ff;
$stupid-yellow: #f5ca6f;
$happy-green: #56d7b6;
$lavender-purple: #846bf8;

$brand-primary: $dark-cornflower-blue;
$brand-primary100: lighten($brand-primary, 20);
$brand-primary200: lighten($brand-primary, 10);
$brand-primary300: $brand-primary;
$brand-primary400: darken($brand-primary, 20);
$brand-primary500: darken($brand-primary, 40);

$brand-primary-accent: $lavender-purple;
$brand-primary-accent100: lighten($brand-primary-accent, 20);
$brand-primary-accent200: lighten($brand-primary-accent, 10);
$brand-primary-accent300: $brand-primary-accent;
$brand-primary-accent400: darken($brand-primary-accent, 20);
$brand-primary-accent500: darken($brand-primary-accent, 40);

$brand-secondary: $cowork-blue;
$brand-secondary100: lighten($brand-secondary, 40);
$brand-secondary200: lighten($brand-secondary, 20);
$brand-secondary300: $brand-secondary;
$brand-secondary400: darken($brand-secondary, 20);
$brand-secondary500: darken($brand-secondary, 40);

$brand-secondary-accent: $stupid-yellow;
$brand-secondary-accent100: lighten($brand-secondary-accent, 40);
$brand-secondary-accent200: lighten($brand-secondary-accent, 20);
$brand-secondary-accent300: $brand-secondary-accent;
$brand-secondary-accent400: darken($brand-secondary-accent, 20);
$brand-secondary-accent500: darken($brand-secondary-accent, 40);

$color-info: $powder-blue;
$color-success: $brand-secondary400;
$color-warning: $brand-primary400;
$color-danger: $fiery-rose;

$text-dark: #000000;
$text-light: $brand-primary-accent100;
$text-grey: $davys-grey;
$text-grey100: #8f8f8f;
$text-grey200: #acacac;

$border-light: #e3e3e3;
$border-dark: #b7c2d4;

$boxshadow-subtle: rgba(0, 0, 0, 0.15) 0px 2px 5px 0px;
$boxshadow-light: rgba(0, 0, 0, 0.05) 0px 15px 24px 0px;
$boxshadow-light-reverse: rgba(0, 0, 0, 0.05) 0px -15px 24px 0px;
$boxshadow-inset: inset 0px 0px 5px 2px rgba(2, 2, 2, 0.31);

$background-grey: #f9f9f9;
