@import "~styles/_animations";
@import "~styles/_colors";
@import "~styles/_typography";

.date-field {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5em;
  width: 100%;

  .date-field__label {
    margin-bottom: 0.5em;
    font-weight: 600;
  }

  .date-field__field {
    padding: 1em;
    border-radius: 3px;
    border: 1px solid $border-light;
    width: 100%;
    box-sizing: border-box;

    &::placeholder {
      color: $border-light;
      transition: $baseTransition;
    }

    &:hover {
      border-color: $border-dark;

      &::placeholder {
        color: $border-dark;
      }
    }

    &:focus,
    &:active {
      outline: none;
      border-color: $brand-secondary200;

      &::placeholder {
        opacity: 0;
      }
    }

    &:hover,
    &:focus,
    &:active {
      box-shadow: $boxshadow-light;
    }

    &.error {
      border-color: $color-success;
    }
  }

  .date-field__error {
    color: $color-success;
    margin-top: 0.5em;
    margin-bottom: 0;
    margin-left: 0.15em;
  }

  .date-field__hint {
    color: $text-light;
    margin-top: 0.5em;
    margin-bottom: 0;
    margin-left: 0.15em;
  }

  .react-datepicker__day--today {
    background: $brand-primary200;
    border-radius: 50%;
    font-weight: normal;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected {
    background: $brand-primary400;
    border-radius: 50%;
    font-weight: bold;
  }

  .react-datepicker__day:hover,
  .react-datepicker__month-text:hover,
  .react-datepicker__quarter-text:hover,
  .react-datepicker__year-text:hover {
    border-radius: 50%;
    background: $border-light;
  }

  .react-datepicker__month-container {
    box-shadow: $boxshadow-light;
  }

  .react-datepicker__header {
    text-align: center;
    background-color: $brand-primary400;
    border: none;
    border-top-left-radius: 0.3rem;
    position: relative;
  }

  .react-datepicker-popper[data-placement^="bottom"]
    .react-datepicker__triangle,
  .react-datepicker-popper[data-placement^="bottom"]
    .react-datepicker__triangle::before {
    border-top: none;
    border-bottom-color: $brand-primary400;
  }

  .react-datepicker-popper[data-placement^="bottom"] {
    margin-top: 0;
  }

  .react-datepicker__navigation--previous {
    border-right-color: white;
  }

  .react-datepicker__navigation--next {
    border-left-color: white;
  }

  .react-datepicker__day-name {
    color: white;
    font-weight: 400;
  }

  .react-datepicker {
    font-family: $font-regular;
    border: none;
    box-shadow: $boxshadow-light;
  }

  .react-datepicker__current-month {
    color: white;
    font-weight: 100;
    line-height: 1;
    margin-bottom: 0.75em;
  }

  .react-datepicker__month-select,
  .react-datepicker__year-select {
    background: white;
    border-radius: 4px;
    padding: 0.15em 0.25em 0.15em 0.15em;
    border-color: transparent;
  }

  div.react-datepicker-popper {
    z-index: 1000 !important;
  }
}
