@import "~styles/_colors";
@import "~styles/_animations";

.data-table-head {
  .data-table-header-row {
    border-bottom: 1px solid $border-light;
  }

  .data-table-column-header {
    text-align: left;
    color: $text-light;
    font-weight: 400;
    font-size: 0.8em;
    text-transform: uppercase;
    padding: 1em;
    transition: $baseTransition;

    .data-header-faded {
      opacity: 0;
      transition: $baseTransition;
    }

    &:hover {
      .data-header-faded {
        opacity: 1;
      }
    }

    .data-column-sort-icon {
      width: 1em;
      height: 1.2em;
      display: inline-block;
      vertical-align: middle;

      svg {
        width: 1em;
        height: 1em;
      }
    }
  }
}
