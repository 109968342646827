@import "~styles/_colors";

.local-modal-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 10;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transform: scale(0);
}

.local-modal-overlay {
  background: rgba(0, 0, 0, 0.32);
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 10;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  backdrop-filter: blur(2px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.local-modal-inner {
  background: white;
  padding: 1em;
  border-radius: 6px;
  box-shadow: $boxshadow-light;
  text-align: center;
  z-index: 11;
  font-weight: normal;
}

.animated-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 10;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.animated-modal-enter {
  opacity: 0;
  transform: scale(0.9);
}
.animated-modal-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition:
    opacity 300ms,
    transform 300ms;
}
.animated-modal-enter-done {
  opacity: 1;
  transform: translateX(0);
  transition:
    opacity 300ms,
    transform 300ms;
  transform: scale(1);
}
.animated-modal-exit {
  opacity: 1;
}
.animated-modal-exit-active {
  opacity: 0;
  transform: scale(0);
  transition:
    opacity 300ms,
    transform 300ms;
}
