@font-face {
  font-family: "Rubik-Light";
  src:
    local("Rubik-Light"),
    url("../assets/fonts/Rubik/Rubik-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Rubik-Regular";
  src:
    local("Rubik-Regular"),
    url("../assets/fonts/Rubik/Rubik-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Rubik-SemiBold";
  src:
    local("Rubik-SemiBold"),
    url("../assets/fonts/Rubik/Rubik-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "SourceSansPro-Light";
  src:
    local("SourceSansPro-Light"),
    url("../assets/fonts/SourceSansPro/SourceSansPro-Light.ttf")
      format("truetype");
}

@font-face {
  font-family: "SourceSansPro-Regular";
  src:
    local("SourceSansPro-Regular"),
    url("../assets/fonts/SourceSansPro/SourceSansPro-Regular.ttf")
      format("truetype");
}

@font-face {
  font-family: "SourceSansPro-SemiBold";
  src:
    local("SourceSansPro-SemiBold"),
    url("../assets/fonts/SourceSansPro/SourceSansPro-SemiBold.ttf")
      format("truetype");
}

@font-face {
  font-family: "Omnes-Light";
  src:
    local("Omnes-Light"),
    url("../assets/fonts/Omnes/Omnes-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Omnes-Light-Italic";
  src:
    local("Omnes-Light-Italic"),
    url("../assets/fonts/Omnes/Omnes-Light-Italic.ttf") format("truetype");
}

@font-face {
  font-family: "Omnes-SemiBold";
  src:
    local("Omnes-SemiBold"),
    url("../assets/fonts/Omnes/Omnes-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Omnes-SemiBold-Italic";
  src:
    local("Omnes-SemiBold-Italic"),
    url("../assets/fonts/Omnes/Omnes-SemiBold-Italic.ttf") format("truetype");
}

$font-logo-regular: "Omnes-SemiBold", sans-serif;
$font-logo-bold: "Omnes-SemiBold", sans-serif;

$font-heading-light: "Rubik-Light", sans-serif;
$font-heading-regular: "Rubik-Regular", sans-serif;
$font-heading-bold: "Rubik-SemiBold", sans-serif;

$font-light: "SourceSansPro-Light", sans-serif;
$font-regular: "SourceSansPro-Regular", sans-serif;
$font-bold: "SourceSansPro-SemiBold", sans-serif;
