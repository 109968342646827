@import "~styles/_typography";
.page-title__wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.page-title {
  font-family: $font-heading-regular;
  font-weight: 300;
  font-size: 1.4em;
  margin: 0.5em 0.5em 0.5em 0em;
}
