@import "~styles/_colors";

.page-empty-state {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  background: white;
  border-radius: 6px;
  box-shadow: $boxshadow-light;
  padding: 1em;
  margin-top: 2em;
}

.page-empty-state__body {
  max-width: 550px;
}
