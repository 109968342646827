@import "~styles/_colors";
.mobile-page-header__action {
  width: 1.5em;
  height: 1.5em;
  cursor: pointer;

  svg {
    width: 1.5em;
    height: 1.5em;

    path {
      fill: $brand-secondary200;
    }
  }
}
