$baseTransition: all 0.35s ease-in-out;

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@mixin staggered-z-index {
  @for $i from 1 through 25 {
    &:nth-child(#{$i}) {
      z-index: $i;
    }
  }
}
