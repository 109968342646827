.page-loading__wrapper {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.page-loading__loader {
  width: 4em;
  height: 4em;
  margin-bottom: 1em;
  animation: crescendo 4.5s alternate infinite ease-in;

  // .spinner-primary {
  //   fill: darken($border-light, 20);
  // }

  // .spinner-secondary {
  //   fill: darken($border-light, 10);
  // }

  @keyframes crescendo {
    0% {
      transform: scale3d(0.8, 0.8, 0.8);
    }
    100% {
      transform: scale3d(1, 1, 1);
    }
  }
}
