@import "~styles/_spacing";
@import "~styles/_colors";

.app-body {
  width: 100%;
  box-sizing: border-box;
  height: 100vh;
  background: $background-grey;
  padding-inline-start: 155px;

  @media (max-width: $viewport7) {
    padding-left: 0;
    padding-bottom: 56px;
    padding-top: 56px;
  }
}

.app-content {
  padding: 0em 2em;
  height: 100vh;
  box-sizing: content-box;
  overflow: auto;
}
