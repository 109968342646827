@import "typography";
@import "spacing";
@import "animations";
@import "colors";

// Brand
.logo-primary {
  fill: $brand-secondary200;
}

.logo-secondary {
  fill: white;
}

// Basics/Layouts
html {
  scroll-behavior: smooth;
  @media (max-width: $viewport7) {
    &.js-focus-visible {
      font-size: 14px;
    }
  }
}

body {
  margin: 0;
  font-family: $font-regular;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.section {
  padding-top: $padding;
  padding-bottom: $padding;
  padding-left: $padding * 2;
  padding-right: $padding * 2;

  @media (min-width: $viewport12) {
    padding-left: $padding * 4;
    padding-right: $padding * 4;
  }
}

.flex {
  display: flex;
  flex-direction: row;

  &.col {
    flex-direction: column;
  }

  &.align-center {
    align-items: center;
  }

  &.justify-center {
    justify-content: center;
  }

  &.justify-between {
    justify-content: space-between;
  }
}

// Responsive

@media (max-width: #{$viewport7 - 1}) {
  .mobile-column {
    flex-direction: column;
  }
}

@media (max-width: #{$viewport7 - 1}) {
  .sm-1 {
    width: $c1;
  }
  .sm-2 {
    width: $c2;
  }
  .sm-3 {
    width: $c3;
  }
  .sm-4 {
    width: $c4;
  }
  .sm-5 {
    width: $c5;
  }
  .sm-6 {
    width: $c6;
  }
  .sm-7 {
    width: $c7;
  }
  .sm-8 {
    width: $c8;
  }
}

@media (min-width: $viewport7) {
  .mlg-1 {
    width: $c1;
  }
  .mlg-2 {
    width: $c2;
  }
  .mlg-3 {
    width: $c3;
  }
  .mlg-4 {
    width: $c4;
  }
  .mlg-5 {
    width: $c5;
  }
  .mlg-6 {
    width: $c6;
  }
  .mlg-7 {
    width: $c7;
  }
  .mlg-8 {
    width: $c8;
  }
}

@media (min-width: $viewport9) {
  .t-1 {
    width: $c1;
  }
  .t-2 {
    width: $c2;
  }
  .t-3 {
    width: $c3;
  }
  .t-4 {
    width: $c4;
  }
  .t-5 {
    width: $c5;
  }
  .t-6 {
    width: $c6;
  }
  .t-7 {
    width: $c7;
  }
  .t-8 {
    width: $c8;
  }
}

@media (min-width: $viewport12) {
  .d-1 {
    width: $c1;
  }
  .d-2 {
    width: $c2;
  }
  .d-3 {
    width: $c3;
  }
  .d-4 {
    width: $c4;
  }
  .d-5 {
    width: $c5;
  }
  .d-6 {
    width: $c6;
  }
  .d-7 {
    width: $c7;
  }
  .d-8 {
    width: $c8;
  }
}

.v4AndUp {
  display: none;
  @media (min-width: $viewport4) {
    display: flex;
  }
}

.v7AndUp {
  display: none;
  @media (min-width: $viewport7) {
    display: flex;
  }
}

.v9AndUp {
  display: none;
  @media (min-width: $viewport9) {
    display: flex;
  }
}

.v12AndUp {
  display: none;
  @media (min-width: $viewport12) {
    display: flex;
  }
}

// Animations

.transition {
  transition: $baseTransition;
  -moz-transition: $baseTransition;
  -webkit-transition: $baseTransition;
  -o-transition: $baseTransition;
  -ms-transition: $baseTransition;
  -transition: $baseTransition;
}

// Typography

html {
  font-size: 16px;
}

a {
  color: $brand-secondary;
  text-decoration: none;
  transition: $baseTransition;

  &:hover {
    color: darken($brand-primary400, 10);
  }

  &:active,
  &:focus {
    color: $brand-secondary400;
  }
}

h1 {
  &.h1-1 {
    font-size: 2.2em;
    margin-bottom: 0.5em;
    @media (max-width: $viewport7) {
      font-size: 1.8em;
    }
  }
}

h2 {
  &.h2-1 {
    font-size: 1.5em;
    margin-bottom: 2em;
    @media (max-width: $viewport7) {
      font-size: 1em;
    }
  }

  &.h2-2 {
    font-size: 2em;
    margin-bottom: 0.5em;
  }

  &.h2-3 {
    font-size: 3em;
    margin-bottom: 0.5em;
  }
}

h3 {
  &.h3-2 {
    font-size: 1.3em;
    margin-bottom: 0.5em;
  }
}

p {
  margin-top: 0;
  font-size: 1em;

  &.p-2 {
    font-size: 1.2em;
  }
}

// 3rd-party Overrides
.custom-toast-progress {
  &.Toastify__progress-bar--default {
    background: $brand-secondary300;
  }
}
