@import "~styles/_colors";

.thought-distortion-option {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid $border-light;

  .select__option {
    padding: 0.5em 1em 1em;

    &:hover,
    &.select__option--is-selected {
      background: lighten($powder-blue, 10);
    }
  }

  .thought-distortion-option__label {
    font-weight: 600;
    color: $text-dark;
    margin-bottom: 0.05em;
  }

  .thought-distortion-option__explanation {
    font-weight: 300;
    color: $brand-secondary300;
    margin-bottom: 0.5em;
  }

  .thought-distortion-option__example {
    font-size: 0.8em;
    font-style: italic;
    color: $brand-primary-accent300;
    display: flex;
  }

  .thought-distortion-option__example--icon {
    width: 0.8em;
    height: 0.8em;
    margin-right: 0.8em;
    margin-bottom: 0.2em;

    svg {
      width: 0.8em;
      height: 0.8em;
      path {
        fill: $text-light;
      }
    }
  }
}
