@import "~styles/_colors";
@import "~styles/_animations";

.submission-card {
  padding: 1em;
  border: 3px solid transparent;
  margin: 0.5em 0em;
  border-radius: 4px;
  box-shadow: $boxshadow-subtle;
  transition: 0.25s;
  position: relative;
  cursor: pointer;
  background: white;

  &:after {
    visibility: hidden;
    opacity: 0;
    width: 15px;
    content: "";
    background: $brand-secondary200;
    position: absolute;
    right: -3px;
    top: -3px;
    bottom: -3px;
    border-radius: 100% 4px 4px 100%;
    transition: 0.25s;
  }

  &:hover,
  &.submission-card__active {
    box-shadow: $boxshadow-light;

    &:after {
      visibility: visible;
      opacity: 1;
    }
  }
}

.submission-card_title {
  font-weight: bold;
  display: flex;
  align-items: center;
}

.submission-card_subtitle {
  font-size: 0.8em;
  color: $text-light;
  display: flex;
  align-items: center;
  margin-top: 0.5em;
}
