@import "~styles/_colors";
@import "~styles/_animations";

.data-table-row {
  transition: $baseTransition;
  border-top: 1px solid $border-light;
  cursor: pointer;

  &:hover {
    transition: $baseTransition;
    border-top: 1px solid white;
    box-shadow: rgba(0, 0, 0, 0.05) 1px 2px 3px 3px;
  }

  .data-table-cell {
    padding: 1em 0.75em;
  }
}

#patients-table {
  .data-table-row {
    cursor: default;
  }
}
