@import "~styles/_colors";
@import "~styles/_animations";

.button {
  background: $brand-secondary;
  background: linear-gradient(
    to right,
    $brand-secondary,
    $brand-primary-accent,
    $brand-secondary
  );
  background-size: 200%;
  background-position: left;
  transition: $baseTransition;
  border: 1px solid $brand-secondary;
  border-radius: 3px;
  padding: 1em 2em;
  color: white;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  text-align: center;
  outline: none;

  display: flex;
  justify-content: center;
  align-items: center;

  &.small {
    padding: 0.65em 1.25em;
    font-size: 0.75em;
  }

  .button__icon {
    svg {
      fill: white;
    }
  }

  &:hover {
    background-position: right;
    box-shadow: $boxshadow-light;
  }

  &:disabled {
    background: $border-light;
    border-color: $border-light;
  }

  &:active,
  &:focus {
    background: $brand-secondary200;
    border-color: $brand-secondary200;
  }

  &.secondary {
    background: white;
    color: $brand-secondary300;
    border-color: $brand-secondary300;

    .button__icon {
      svg {
        fill: $brand-secondary300;
      }
    }

    &:hover {
      background: lighten($brand-secondary300, 3);
      border-color: lighten($brand-secondary300, 3);
      box-shadow: $boxshadow-light;
      color: white;

      .button__icon {
        svg {
          fill: white;
        }
      }
    }

    &:disabled {
      background: $border-light;
      border-color: $border-light;
      color: white;
      .button__icon {
        svg {
          fill: white;
        }
      }
    }

    &:active,
    &:focus {
      background: white;
      border-color: $brand-secondary200;
      color: $brand-secondary200;
      .button__icon {
        svg {
          fill: $brand-secondary200;
        }
      }
    }
  }

  &.secondary-bland {
    background: white;
    color: $brand-primary;
    border-color: $brand-primary;

    .button__icon {
      svg {
        fill: $brand-primary;
      }
    }

    &:hover {
      background: $brand-primary200;
      border-color: $brand-primary200;
      box-shadow: $boxshadow-light;
      color: white;

      .button__icon {
        svg {
          fill: white;
        }
      }
    }

    &:disabled {
      background: $border-light;
      border-color: $border-light;
      color: white;
      .button__icon {
        svg {
          fill: white;
        }
      }
    }

    &:active,
    &:focus {
      background: white;
      border-color: $brand-secondary-accent300;
      color: $brand-secondary-accent300;
      .button__icon {
        svg {
          fill: $brand-secondary-accent300;
        }
      }
    }
  }

  &.danger {
    background: $color-danger;
    color: white;
    border-color: $color-danger;

    .button__icon {
      svg {
        fill: white;
      }
    }

    &:hover {
      background: lighten($color-danger, 3);
      border-color: lighten($color-danger, 3);
      box-shadow: $boxshadow-light;
      color: white;

      .button__icon {
        svg {
          fill: white;
        }
      }
    }

    &:disabled {
      background: $border-light;
      border-color: $border-light;
      color: white;
      .button__icon {
        svg {
          fill: white;
        }
      }
    }

    &:active,
    &:focus {
      background: white;
      border-color: $color-danger;
      color: $color-danger;
      .button__icon {
        svg {
          fill: $color-danger;
        }
      }
    }
  }

  &.pill {
    border-radius: 4em;
  }

  &.full-width {
    width: 100%;
  }

  &.one-fifty-width {
    width: 150px;
  }

  .button__loader {
    width: 1em;
    height: 1em;
    margin-right: 1em;
    animation: rotating 1s linear infinite;

    .spinner-primary {
      fill: darken($border-light, 20);
    }

    .spinner-secondary {
      fill: darken($border-light, 10);
    }
  }

  .button__icon {
    width: 1.5em;
    height: 1.5em;
    margin-right: 1em;

    svg {
      width: 1.5em;
      height: 1.5em;
    }
  }
}
