@import "~styles/_typography";
@import "~styles/_colors";
@import "~styles/_animations";
@import "~styles/_spacing";

.stats-card {
  display: flex;
  flex-direction: row;
  margin-bottom: 1em;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  padding: 1em;
  cursor: pointer;
  border: 1px solid transparent;
  transition: $baseTransition;

  &:hover {
    border: 1px solid lighten($border-light, 5%);
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.09);
  }

  .stats-card_right {
    display: flex;
    flex-direction: row;
  }

  .stats-card_icon--wrapper {
    width: 4em;
    height: 4em;
    display: flex;
    align-items: center;
    justify-content: center;
    background: lighten($border-light, 5);
    border-radius: 1em;
    margin-right: 1em;
  }

  .stats-card_icon {
    width: 2em;
    height: 2em;

    svg {
      width: 2em;
      height: 2em;
      path {
        fill: $brand-primary-accent200;
      }
    }
  }

  &.primary {
    .stats-card_icon {
      svg {
        path {
          fill: $brand-primary400;
        }
      }
    }
  }

  &.secondary {
    .stats-card_icon {
      svg {
        path {
          fill: $brand-secondary300;
        }
      }
    }
  }

  &.primary-accent {
    .stats-card_icon {
      svg {
        path {
          fill: $brand-primary-accent300;
        }
      }
    }
  }

  .stats-card_data {
    .stats-card_count {
      margin-bottom: 0;
      font-size: 2em;
      font-weight: bold;
      font-family: $font-heading-regular;
    }

    .stats-card_title {
      margin-bottom: 0;
    }
  }

  .stats-car__nav-arrow {
    width: 1.5em;
    height: 1.5em;

    svg {
      width: 1.5em;
      height: 1.5em;
      path {
        fill: $brand-primary-accent200;
      }
    }
  }
}

@media (max-width: $viewport9) {
  .stats-car__nav-arrow {
    display: none;
  }
}

@media (max-width: $viewport7) {
  .stats-car__nav-arrow {
    display: block;
  }
}
