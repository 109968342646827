@import "~styles/_colors";

.assignment-icon {
  width: 1em;
  height: 1em;
  margin-right: 1em;
  background: $border-light;
  padding: 0.5em;
  border-radius: 6px;

  &.THOUGHT_RECORD {
    background: $brand-primary200;
  }

  &.MOOD_TRACKER {
    background: $brand-secondary-accent200;
  }

  &.JOURNAL {
    background: $brand-primary-accent200;
  }

  svg {
    width: 1em;
    height: 1em;
    path {
      fill: white;
    }
  }

  &.small {
    width: 0.5em;
    height: 0.5em;
    margin-right: 0.5em;
    border-radius: 3px;
    display: flex;
    svg {
      width: 0.5em;
      height: 0.5em;
      path {
        fill: white;
      }
    }
  }

  &.medium {
    width: 1em;
    height: 1em;
    margin-right: 0.5em;
    border-radius: 3px;
    display: flex;
    svg {
      width: 1em;
      height: 1em;
      path {
        fill: white;
      }
    }
  }
}
