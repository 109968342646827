@import "~styles/_colors";
@import "~styles/_animations";
@import "~styles/_spacing";
@import "~styles/_typography";
.side-nav {
  background: white;
  height: 100vh;
  width: $sideNavWidth;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  transition: $baseTransition;
  z-index: 2;
  box-shadow: 0px 0px 4px 0px #0000002e;

  .side-nav__top__wrapper {
    width: 100%;
  }

  .side-nav__top {
    transition: $baseTransition;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: $sideNavWidth;
    padding-top: 0.5em;
    height: 42px;
    margin-bottom: 3em;
  }

  .side-nav__brand {
    transition: $baseTransition;
    transition-delay: 0s;
    opacity: 0;
    visibility: hidden;
    width: 0px;
    color: $text-dark;
    white-space: nowrap;
    font-family: $font-logo-bold;
    font-size: 0.9em;
  }

  .side-nav__logo {
    transition: $baseTransition;
    width: 1.5em;
    display: flex;
    justify-content: center;

    svg {
      width: 100%;
      fill: $brand-primary;
    }
  }

  .side-nav__menu__wrapper {
    width: 100%;
  }

  .side-nav__menu {
    padding: 0;
    list-style: none;
    width: 100%;
  }

  .side-nav__expand-toggle {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 1em 1em 0 1em;
    box-sizing: border-box;
    cursor: pointer;

    svg {
      width: 1.5em;
      height: 1.5em;
      transition: $baseTransition;
      transform: rotate(0deg);
      fill: $brand-primary;
    }
  }

  &.side-nav__expanded {
    box-shadow: $boxshadow-light;
    transition: $baseTransition;
    width: 155px;
    position: fixed;

    .side-nav__expand-toggle {
      svg {
        transform: rotate(180deg);
      }
    }

    .side-nav__top {
      transition-delay: 0.35s;
    }

    .side-nav__brand {
      transition-delay: 0.5s;
      opacity: 1;
      visibility: visible;
      white-space: nowrap;
      margin-left: 0.5em;
    }
  }

  @media (max-width: $viewport7) {
    &.side-nav__expanded {
      width: 100%;
    }
    .side-nav__top {
      margin-top: 1em;
    }
  }
}
