@import "~styles/_colors";
@import "~styles/_animations";
@import "~styles/_spacing";

.mobile-bottom-nav-item {
  transition: $baseTransition;
  padding: 1em 0.5em 1em 1em;
  cursor: pointer;
  border-top: 2px solid transparent;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  position: relative;
  height: 56px;
  justify-content: center;

  &:hover,
  &.active {
    border-top: 4px solid $brand-secondary;

    .mobile-bottom-nav-item__icon {
      svg {
        fill: $brand-primary;
      }
    }

    .mobile-bottom-nav-item__label {
      color: $brand-primary;
    }
  }
}

.mobile-bottom-nav-item__icon {
  width: 1.5em;
  height: 1.5em;

  svg {
    width: 1.5em;
    height: 1.5em;
    fill: $brand-primary;
  }
}

.mobile-bottom-nav-item__label {
  transition: $baseTransition;
  opacity: 0;
  visibility: hidden;
  width: 0px;
  margin-left: 0;
  transform: scale(0);
  color: $brand-primary;
  white-space: nowrap;
}

.mobile-bottom-nav-item__dot {
  height: 0.4em;
  width: 0.4em;
  background: $color-danger;
  border-radius: 100%;
  position: absolute;
  right: 0.3em;
  top: 0.5em;
  box-shadow: 0px 0px 4px 1px $color-danger;
}
