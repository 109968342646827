@import "~styles/_spacing";
@import "~styles/_colors";

.authentication-layout-wrapper {
  background-color: $brand-primary;
  justify-content: center;
  align-items: center;
  padding: 1em 0em;
  min-height: calc(100vh - 2em);
}

.authentication-card {
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 8px;
  min-width: 350px;
  padding: 2em 2em 3em 2em;
}

.authentication-card__logo {
  margin-bottom: 2em;
}
