@import "~styles/_animations";
@import "~styles/_colors";

.string-field {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5em;

  .string-field__label {
    margin-bottom: 0.5em;
  }

  .string-field__field {
    padding: 1em;
    border-radius: 3px;
    border: 1px solid $border-light;

    &::placeholder {
      color: $border-light;
      transition: $baseTransition;
    }

    &:hover {
      border-color: $border-dark;

      &::placeholder {
        color: $border-dark;
      }
    }

    &:focus,
    &:active {
      outline: none;
      border-color: $brand-secondary200;

      &::placeholder {
        opacity: 0;
      }
    }

    &:hover,
    &:focus,
    &:active {
      box-shadow: $boxshadow-light;
    }

    &.error {
      border-color: $color-success;
    }
  }

  .string-field__error {
    color: $color-success;
    margin-top: 0.5em;
    margin-bottom: 0;
    margin-left: 0.15em;
  }

  .string-field__hint {
    color: $text-light;
    margin-top: 0.5em;
    margin-bottom: 0;
    margin-left: 0.15em;
  }
}
