@import "~styles/_animations";
@import "~styles/_colors";
@import "~styles/_typography";

.textarea {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5em;
  font-family: $font-regular;

  .textarea__label {
    font-weight: bold;
    margin-bottom: 0.5em;
  }

  .textarea__field {
    padding: 1em;
    border-radius: 3px;
    border: 1px solid $border-light;
    font-family: $font-regular;

    &::placeholder {
      color: $border-light;
      transition: $baseTransition;
    }

    &:hover {
      border-color: $border-dark;

      &::placeholder {
        color: $border-dark;
      }
    }

    &:focus,
    &:active {
      outline: none;
      border-color: $brand-secondary200;

      &::placeholder {
        opacity: 0;
      }
    }

    &:hover,
    &:focus,
    &:active {
      box-shadow: $boxshadow-light;
    }

    &.error {
      border-color: $color-danger;
    }
  }

  .textarea__hint {
    color: $text-grey;
    margin-top: 0.5em;
    margin-bottom: 0;
    margin-left: 0.15em;
  }

  .textarea__error {
    color: $color-danger;
    margin-top: 0.5em;
    margin-bottom: 0;
    margin-left: 0.15em;
  }

  .textarea__optional-label {
    font-weight: 400;
  }
}
