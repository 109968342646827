@import "~styles/_typography";
@import "~styles/_colors";
@import "~styles/_animations";
@import "~styles/_spacing";

.submission-stats-card {
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;
  justify-content: space-between;
  align-items: flex-start;
  background: white;
  padding: 1em;
  border: 1px solid lighten($border-light, 5%);
  border-radius: 4px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.09);
  border-top: 3px solid $brand-secondary;

  .submission-stats-card_section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  .submission-stats-card_section-title {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .submission-stats-card_intro {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 1.5em;
    width: 150px;
  }

  .submission-stats-card_section-title {
    margin-bottom: 0;
    font-size: 1.2em;
    font-weight: bold;
    font-family: $font-heading-regular;
  }

  .submission-stats-card_main-title {
    margin-bottom: 0;
    font-size: 1.8em;
    font-weight: bold;
    font-family: $font-bold;
  }

  .submission-stats-card_icon--wrapper {
    width: 4em;
    height: 4em;
    display: flex;
    align-items: center;
    justify-content: center;
    background: lighten($border-light, 5);
    border-radius: 1em;
    margin-right: 1em;

    &.bump-left {
      margin-left: 1em;
    }
  }

  .submission-stats-card_icon {
    width: 2em;
    height: 2em;

    svg {
      width: 2em;
      height: 2em;
      path {
        fill: $brand-secondary;
      }
    }
  }

  .submission-stats-card_data {
    display: flex;
    flex-direction: row;
    align-items: center;

    .submission-stats-card_count {
      margin-bottom: 0;
      font-size: 2em;
      font-weight: bold;
      font-family: $font-heading-regular;
      margin-right: 0.25em;
    }

    .submission-stats-card_title {
      margin-bottom: 0;
    }
  }

  .submission-stats-card_table {
    display: flex;
    flex-direction: row;

    .submission-stats-card_icon--wrapper {
      width: 3em;
      height: 3em;
      border-radius: 0.5em;
      margin-right: 0.5em;
    }

    .submission-stats-card_icon {
      width: 1.5em;
      height: 1.5em;

      svg {
        width: 1.5em;
        height: 1.5em;
        path {
          fill: red;
        }
      }
    }

    .submission-stats-card_column {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin: 0 1.5em;
      border-left: 1px solid $border-light;
      padding-left: 1em;

      &:first-child {
        border: none;
        padding-left: 0;
        margin-left: 1em;
      }

      .submission-stats-card_count {
        margin-bottom: 0;
        font-size: 1.4em;
        font-weight: normal;
        font-family: $font-heading-regular;
      }

      .submission-stats-card_title {
        font-size: 0.8em;
        margin-bottom: 0;
        text-transform: uppercase;
      }
    }
  }

  .submission-stats-car__nav-arrow {
    width: 1.5em;
    height: 1.5em;

    svg {
      width: 1.5em;
      height: 1.5em;
      path {
        fill: $brand-secondary;
      }
    }
  }
}

@media (max-width: $viewport9) {
  .submission-stats-car__nav-arrow {
    display: none;
  }
}

@media (max-width: $viewport7) {
  .submission-stats-car__nav-arrow {
    display: block;
  }
}
