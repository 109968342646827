@import "~styles/_spacing";

.side-panel {
  transition: 0.25s;
  z-index: 999;
  background: white;
  padding: 0.5em;
  box-sizing: border-box;
  box-shadow: -6px 0px 20px 20px #00000036;
  max-width: 100vw;

  .slide-pane__content {
    padding: 0em 1.25em;
  }
}

.side-panel__header {
  border-bottom: 1px solid #e3e3e3;
  margin-bottom: 1.5em;
  padding: 1em 0em 0.5em;
}
.side-panel__header-title {
  margin: 0;
}

.side-panel__header-icon {
  width: 1.5em;
  height: 1.5em;
  padding-right: 0.5em;
  margin-right: 1em;
  border-right: 1px solid #e3e3e3;
  cursor: pointer;

  svg {
    width: 1.5em;
    height: 1.5em;
  }
}

.side-panel-background {
  backdrop-filter: blur(2px);
  z-index: 2;
}

@media (min-width: $viewport4) {
  .side-panel {
    width: $viewport4 !important;
    max-width: 100vw;
  }
}
