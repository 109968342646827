@import "~styles/_colors";
@import "~styles/_animations";

.data-table__pagination {
  background: white;
  border-radius: 0px 0px 8px 8px;
  padding: 1em 0.5em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  box-shadow: $boxshadow-light;
  border-top: 1px solid $border-light;
}

.data-table__pagination-buttons {
  display: flex;
}

.data-table__pagination-buttons--button {
  transition: $baseTransition;
  background: $brand-primary300;
  border-radius: 100%;
  width: 1.5em;
  height: 1.5em;
  border: 1px solid transparent;
  margin: 0 0.25em;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    width: 0.75em;
    height: 0.75em;
    path {
      fill: white;
    }
  }

  &:active,
  &:focus,
  &:hover {
    border: 1px solid $brand-primary300;
    background: white;
    svg {
      path {
        fill: $brand-primary300;
      }
    }
  }

  &.pagination-disabled {
    opacity: 0.6;
    pointer-events: none;
  }
}

.data-table__pagination-page-count {
  font-size: 0.8em;
  margin-left: 0.5em;
  color: $text-light;
}

.data-table__pagination-page-separator {
  font-size: 0.8em;
  color: $text-light;
  margin: 0em 0.5em;
}

.data-table__pagination-page-input,
.data-table__pagination-count-select {
  font-size: 0.8em;
  color: $text-light;
}

.data-table__pagination-count-select {
  margin-right: 0;
}

.data-table__pagination-page-input-field,
.data-table__pagination-count-select-field {
  margin-left: 0.5em;
  border-radius: 4px;
  border: 1px solid $border-light;
  padding: 0.5em 0em 0.5em 0.5em;
  font-size: 0.8em;
  width: 3em;

  &:focus,
  &:active {
    border: 1px solid $brand-primary300;
    outline-color: $brand-primary300;
  }
}

.data-table__pagination-count-select-field {
  margin: 0em;
  width: auto;
  padding: 0.5em;
}
