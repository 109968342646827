@import "~styles/_colors";
@import "~styles/_animations";
@import "~styles/_spacing";

.side-nav-item {
  transition: $baseTransition;
  padding: 1em 0.5em 1em 1em;
  cursor: pointer;
  border-right: 2px solid transparent;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  position: relative;
  height: 50px;
  margin-bottom: 0.5em;

  &:hover,
  &.active {
    border-right: 4px solid $brand-primary;
    border-radius: 4px;

    .side-nav-item__icon {
      svg {
        fill: $brand-primary;
      }
    }

    .side-nav-item__label {
      color: $text-dark;
    }

    @media (max-width: $viewport7) {
      background: lighten($brand-secondary200, 25);
    }
  }
}

.side-nav-item__icon {
  width: 1.5em;
  height: 1.5em;

  svg {
    width: 1.5em;
    height: 1.5em;
    fill: $text-grey200;
  }
}

.side-nav-item__label {
  transition: $baseTransition;
  opacity: 0;
  visibility: hidden;
  width: 0px;
  margin-left: 0;
  transform: scale(0);
  color: $text-grey200;
  white-space: nowrap;
}

.side-nav-item__dot {
  height: 0.4em;
  width: 0.4em;
  background: $color-danger;
  border-radius: 100%;
  position: absolute;
  right: 0.3em;
  top: 0.5em;
  box-shadow: 0px 0px 4px 1px $color-danger;
}

.side-nav-item__expanded {
  .side-nav-item__label {
    opacity: 1;
    visibility: visible;
    width: auto;
    margin-left: 1em;
    transform: scale(1);
    font-size: 0.8em;
    white-space: nowrap;
  }
}
